/**
 * Content model.  Fetches an object containing all the relevant
 * content for a given business.
 * @class
 */
class Content {
  /**
   * @constructor
   * @param {Object} firebase - initialized firebase app
   * @param {string} business - business name
   */
  constructor ({ firebase }, business) {
    this.firebase = firebase
    this.ref = firebase.database().ref().child(business).child('content')
  }

  /**
   * Get configuration
   * @returns {Promise} Resolves with {Object}
   */
  async get () {
    return this.ref.once('value').then(snapshot => snapshot.val())
  }
} // class

export default Content

/**
 * The rebook node in the database holds a list of guests.  It is used as a
 * parking-lot to retain guest details when a checked-in guest moves to a
 * different activity.  This class is used to list, add, and remove guests.
 */
class Rebook {
  /**
   * @constructor
   * @param {Object} firebase - initialized firebase app
   */
  constructor ({ firebase }, business) {
    this.firebase = firebase
    this.ref = firebase.database().ref(business).child('rebook')
  }

  /**
   * Add a guest to the rebook list.
   * @param {Object} guest
   * @returns {Promise}
   */
  add (guest) {
    return this.ref.child(guest.booking).child(guest.key).set(guest)
  }

  /**
   * Remove a guest from the rebook list
   * @param {Object} guest
   * @returns {Promise}
   */
  remove (guest) {
    return this.ref.child(guest.booking).child(guest.key).remove()
  }

  /**
   * Remove all guests and booking number from rebook list
   * @param {number} bookingNumber
   * @returns {Promise}
   */
  removeBooking (bookingNumber) {
    if (!bookingNumber) return Promise.reject(TypeError('removeBooking requires a booking number.'))
    return this.ref.child(bookingNumber).remove()
  }

  /**
   * Get a list of all guests in the rebook list.
   * @returns {Promise} Resolves with 3D array of guests grouped by booking number.
   */
  guests () {
    return this.ref.orderByKey().once('value').then((snapshot) => {
      const guests = {}
      snapshot.forEach((booking) => {
        guests[booking.key] = this.snapToArray(booking)
      })
      return guests
    })
  }

  guestsForBooking (bookingNumber) {
    return this.ref.child(bookingNumber).orderByKey().once('value').then(snapshot => this.snapToArray(snapshot))
  }

  /**
   * Handle booking-added event.  Triggers when a new booking is added
   * to the rebook list.
   */
  onBookingAdded (callback) {
    return this.ref.on('child_added', (snapshot) => {
      callback(snapshot.key, snapshot.val())
    })
  }

  onBookingUpdated (callback) {
    return this.ref.on('child_changed', (snapshot) => {
      callback(snapshot.key, snapshot.val())
    })
  }

  onBookingRemoved (callback) {
    return this.ref.on('child_removed', (snapshot) => {
      callback(snapshot.key, snapshot.val())
    })
  }

  /**
   * Turn a Firebase DataSnapshot into an array.
   * @private
   * @param {DataSnapshot}
   * @returns {Object[]}
   */
  snapToArray (snapshot) {
    const arr = []
    if (snapshot.exists()) {
      snapshot.forEach((prop) => {
        arr.push(prop.val())
      })
    }
    return arr
  }
}

module.exports = Rebook

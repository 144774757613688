/**
 * The booking index links booking numbers to activities.  The list of activities
 * stored in the database may be different from those stored in Zaui.  This index
 * is used to compare both and sync changes.
 *
  * When a Zaui webhook is received, it contains only the current state of the
  * booking.  The booking index can be used to retrieve the previous state of
  * the booking and make adjustments, such as rebooking or deleting guests.
  *
  * @class
 */
class BookingIndex {
  /**
   * @constructor
   * @param {Object} firebase - Firebase instance
   */
  constructor (firebase) {
    this.firebase = firebase
  }

  /**
   * Get a firebase database ref.  This allows on instance of the class
   * to handle multiple business units.
   * @private
   * @param {string} business
   * @returns {firebase.database.ref}
   */
  ref (business) {
    return this.firebase.database().ref(business).child('bookings')
  }

  /**
   * Get a booking index database key.  Using a key built from the activity
   * allows activities to be queried without tracking a separate key, as
   * would be required if the key was assigned via `push()`.
   * @private
   * @param {Object} activity
   * @returns {string}
   */
  key (activity) {
    return `${activity.activityDate}_${activity.activityTime}_${activity.activityId}`
  }

  /**
   * Get all activities for a booking.
   * @param {string} business
   * @param {number} booking - booking number
   * @returns {Object[]} Array of activity objects
   */
  get (business, booking) {
    return this.ref(business).child(booking).once('value').then((snapshot) => {
      // convert the snapshot Object into an array, returning an empty array
      // if the snapshot does not exist
      const activities = []
      if (snapshot.exists()) {
        snapshot.forEach(activity => {
          activities.push(activity.val())
        })
      }
      return activities
    })
  }

  /**
   * Add an activity to a booking.  If the activity already exists
   * it won't be added again.
   * @param {string} business
   * @param {number} booking - Zaui booking number
   * @param {Object} activity
   * @returns {Promise}
   */
  add (business, booking, activity) {
    if (!business || !booking || !activity) return Promise.reject(TypeError('invalid arguments'))
    const key = this.key(activity)
    const ref = this.ref(business).child(booking).child(key)
    return ref.update(activity)
  }

  /**
  * Remove an activity from a booking.
  * @param {string} business
  * @param {number} booking
  * @param {Object} activity
  * @returns {Promise}
  */
  remove (business, booking, activity) {
    if (!business || !booking || !activity) return Promise.reject(TypeError('invalid arguments'))
    const key = this.key(activity)
    const ref = this.ref(business).child(booking).child(key)
    return ref.remove()
  }

  /**
   * Removes booking and all indexed activities
   */
  removeBooking (business, booking) {
    const ref = this.ref(business).child(booking)
    return ref.remove()
  }
} // class

module.exports = BookingIndex

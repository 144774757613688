import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js'
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)

/**
 * Get the total number of passengers
 * @param {Object} pax - Zaui 'passengers' node
 * @returns {number}
 */
export function sumPassengers (pax) {
  if (!pax) return NaN
  return parseInt(pax.seniors) + parseInt(pax.adults) + parseInt(pax.students) + parseInt(pax.children) + parseInt(pax.infants)
}

/**
 * Check if all activities on a booking have departed or not.
 * @param {Object} booking - Zaui booking object
 * @param {string} [dateTime] - Optional ISO8601 date and time.
 * @returns {boolean} True if all activities on the booking have departed as of
 * the current moment, or as of the specified dateTime.
 */
export function bookingIsOld (booking, dateTime) {
  if (!booking || !booking.activities || !Array.isArray(booking.activities)) return false
  const now = dayjs(dateTime) || dayjs()
  return booking.activities.every((activity) => {
    const activityDateTime = dayjs(activity.activityDate + ' ' + activity.activityTime, 'YYYY-MM-DD HH:mm:ss').add(1, 'hour')
    return activityDateTime.isSameOrBefore(now, 'second')
  })
}

export default {
  sumPassengers,
  bookingIsOld
}

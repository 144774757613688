import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

/**
 * Detect the user's locale from the Browser settings
 * @returns {string} - two-letter locale if defined
 */
function getBrowserLocale () {
  const navigatorLocale = (navigator.languages !== undefined) ? navigator.languages[0] : navigator.language
  if (!navigatorLocale) return undefined
  return navigatorLocale.trim().split(/-|_/)[0]
}

/**
 * Get the preferred locale.  Uses the browser locale if known and supported.
 * @returns {string} two-letter locale
 */
function getLocale () {
  const browserLocale = getBrowserLocale()
  switch (browserLocale) {
    case 'en':
    case 'fr':
      return browserLocale
    default:
      return process.env.VUE_APP_I18N_LOCALE || 'en'
  }
}

export default new VueI18n({
  locale: getLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'
})

/**
 * Config model.  Fetches an object containing all the relevant public
 * settings for a given business.
 * @class
 */
class Config {
  /**
   * @constructor
   * @param {Object} firebase - initialized firebase app
   * @param {string} business - business name
   */
  constructor ({ firebase }, business) {
    this.firebase = firebase
    this.ref = firebase.database().ref().child(business).child('config').child('public')
  }

  /**
   * Get configuration
   * @returns {Promise} Resolves with {Object}
   */
  async get () {
    // database.rules.json only allow unauthenticated clients to access /:business/config/public
    return this.ref.once('value').then(snapshot => snapshot.val())
  }

  /**
   * Get IP whitelists, without all the rest of it.  Used by
   * Vue Router guards.
   * @returns {Promise} Resolves with an array of IP addresses, or ['0.0.0.0'] if no
   * whitelist is defined.
   */
  async whitelist () {
    return this.ref.child('whitelist').once('value').then(snapshot => {
      if (!snapshot.exists()) return ['0.0.0.0']
      else return snapshot.val()
    })
  }
} // class

export default Config

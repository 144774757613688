import dayjs from 'dayjs'

/**
 * Waivers in the database are updated using a webhook, and read by the client
 * @class
 */
class Waiver {
  /**
  * @constructor
  * @param {Object} firebase - initialized firebase app
  * @param {string} business - name of business
  */
  constructor ({ firebase }, business) {
    this.firebase = firebase
    this.business = business
    this.ref = firebase.database().ref().child(business).child('waivers')
    // add a callable cloud function as a member of this class
    this.smartwaiverLookup = firebase.functions().httpsCallable('smartwaiverLookup')
  }

  /**
   * Zaui tags waivers so they can be associated with bookings.  We'll do the
   * same when generating links to a waiver so Smartwaiver will update Zaui
   * for us.
   * @param {Object} activity
   * @param {Object} guest
   * @returns {string} booking_activityId_unixdatestamp_HH_MM
   */
  zauiTag (activity, guest) {
    const booking = guest.booking
    const activityId = activity.activityId
    const datestamp = dayjs(activity.activityDate).unix()
    const time = dayjs(`${activity.activityDate} ${activity.activityTime}`).format('HH_mm')
    return `${booking}_${activityId}_${datestamp}_${time}`
  }

  /**
   * Link to prefilled waiver.  The prefilled waiver ensures that the name and DOB
   * on the waiver match with the client app.
   * @see https://support.smartwaiver.com/hc/en-us/articles/115015680248-How-do-I-use-Autofill-to-pre-populate-fields-Advanced-
   * @param {string} guide - Smartwaiver template ID of the waiver to be filled
   * @param {Object} activity
   * @param {Object} guest
   * @returns {string} URL of prefilled waiver
   */
  url (guid, activity, guest) {
    const base = `https://waiver.smartwaiver.com/w/${guid}/web/`
    const dob = dayjs(guest.dob).format('YYYYMMDD')
    const tag = this.zauiTag(activity, guest)
    return `${base}?wautofill_firstname=${guest.firstName}&wautofill_lastname=${guest.lastName}&wautofill_dobyyyymmdd=${dob}&wautofill_tag=${tag}`
  }

  /**
   * Lookup waivers in SmartWaiver via cloud function.
   * @param {Object} guest
   * @returns {Promise} Resolves with matching waiver details, or undefined if
   * no waiver was found.  If multiple matches are found, only the most recent
   * is returned.
   */
  async lookup (guest) {
    // there can be no signed waivers if the name is incomplete
    // so don't bother making any further calls
    if (!guest.firstName || !guest.lastName) return undefined
    // get array of all signed waivers with matching participants (by name only)
    let waivers = await this.smartwaiverLookup({
      business: this.business,
      firstName: guest.firstName,
      lastName: guest.lastName
    })
    // remove participants with the wrong birthdate, as the Smartwaiver
    // won't let us search on birthdates
    waivers = waivers.data.filter(waiver => (waiver.dob === guest.dob))
    return waivers[0] // assumes API returns waivers in reverse chronological order
  }

  /**
   * @callback waiverCallback
   * @param {Object} participant - Contains waiver details for an individual
   */

  /**
   * Create an event listener for waivers being added and/or updated in the database.
   * The events are emitted by the smartwaiverWebhook cloud function when incoming
   * waiver data is written to the Firebase waiver cache.
   * @see functions/lib/waivers.js:Waivers.cacheEmit()
   * @param {waiverCallback} callback - function called with { firstName, lastName, dob }
   * when event is emitted.
   */
  on (callback) {
    const ref = this.ref.child('_cacheEvent')
    ref.off() // remove all previous listeners
    ref.on('child_removed', snapshot => {
      if (snapshot.exists()) callback(snapshot.val())
    })
  }
}

export default Waiver

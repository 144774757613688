class Manifest {
  /**
   * @constructor
   * @param {Object} firebase - initialized firebase app
   * @param {string} business - name of business
   */
  constructor ({ firebase }, business) {
    this.business = business
    const functions = firebase.functions()
    this.getDepartures = functions.httpsCallable('getDepartures')
    this.getManifest = functions.httpsCallable('getManifest')
  }

  /**
   * Get a list of all activities
   * @param {string} date - YYYY-MM-DD
   * @returns {Promise} Resolves with an array of Activities
   */
  departures (date) {
    return this.getDepartures({ business: this.business, date: date }).then((res) => {
      return res.data
    })
  }

  /**
   * Get a passenger node that is the sum of two other passenger nodes
   * @private
   * @param {Object} a - First passenger node
   * @param {Object} b - Second passenger node
   * @returns {Object} A passenger node that is sum of the other nodes
   */
  mergePassengers (a, b) {
    if (!a && !b) return { adults: '0', children: '0', infants: '0', seniors: '0', students: '0' }
    else if (!a) return b
    else if (!b) return a
    else {
      return {
        adults: (parseInt(a.adults) + parseInt(b.adults)).toString(),
        children: (parseInt(a.children) + parseInt(b.children)).toString(),
        infants: (parseInt(a.infants) + parseInt(b.infants)).toString(),
        seniors: (parseInt(a.seniors) + parseInt(b.seniors)).toString(),
        students: (parseInt(a.students) + parseInt(b.students)).toString()
      // passengersAsString excluded, format of the string is unclear but could be added
      }
    }
  }

  mergeBookings (bookings) {
    return bookings.reduce((merged, current) => {
      const booking = merged.find((booking) => {
        return booking && (booking.bookingNumber === current.bookingNumber)
      })
      if (!booking) merged.push(current)
      else booking.passengers = this.mergePassengers(booking.passengers, current.passengers)
      return merged
    }, [])
  }

  async getBookings (activity) {
    if (!activity || !activity.activityDate || !activity.activityTime || !activity.activityId) throw TypeError(`bad or missing activity: ${JSON.stringify(activity)}`)
    const manifest = await this.getManifest({
      business: this.business,
      activityDate: activity.activityDate,
      activityTime: activity.activityTime,
      activityId: activity.activityId
    }).then(res => res.data)
    // merge together multiple items for same booking, which occurs when a
    // booking is made with multiple guest names
    return this.mergeBookings(manifest)
  }
}

export default Manifest

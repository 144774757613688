import Vue from 'vue'

/**
 * Client-side logger.  Mimmicks the Winston logger interface.  Logs to
 * Cloud Logger.
 * @class
 */
class Logger {
  /**
   * @constructor
   * @param {Firebase} firebase - initialize app instance with functions
   */
  constructor ({ firebase }) {
    const functions = firebase.functions()
    this.logger = functions.httpsCallable('logger')
    this.ip = functions.httpsCallable('ip')
    // when in production, send client-side errors to the browser console, but
    // also send them to remote/cloud loggers for monitoring and alerting
    if (process.env.NODE_ENV === 'production') {
      Vue.config.errorHandler = (err, vm, info) => {
        console.log(err)
        console.log(process.env.NODE_ENV)
        return this.error(err.stack)
      }
    }
  }

  /**
   * Write a log message.
   * @param {string} level - DEBUG, INFO, WARN, or ERROR
   * @param {string} message
   * @param {Object} [data] - Optional object to write
   */
  log (level, message, data) {
    return this.logger({ level, message, ...data })
  }

  /**
   * Write a debug message.
   * @param {string} message
   * @pararam {Object} [data]
   */
  debug (message, data) {
    const level = 'DEBUG'
    return this.logger({ level, message, ...data })
  }

  /**
   * Write an info message.
   * @param {string} message
   * @pararam {Object} [data]
   */
  info (message, data) {
    const level = 'INFO'
    return this.logger({ level, message, ...data })
  }

  /**
   * Write a warning message
   * @param {string} message
   * @pararam {Object} [data]
   */
  warn (message, data) {
    const level = 'WARN'
    return this.logger({ level, message, ...data })
  }

  /**
   * Write an error message
   * @param {string} message
   * @param {Object} [data]
   */
  error (message, data) {
    const level = 'ERROR'
    return this.logger({ level, message, ...data })
  }

  /**
   * Get useful info about the client, which can be added to log messages
   * @returns {Promise<Object>}
   */
  clientInfo () {
    return this.ip({})
  }
}

export default Logger

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { sync } from 'vuex-router-sync'
import firebase from '../models/firebase.js'
import { config } from '../models'

Vue.use(VueRouter)

/**
 * Get the client's IP address using a Firebase function
 * @returns {string} Client's IP address
 */
function ip () {
  return firebase.functions().httpsCallable('ip')().then(res => {
    return res.data.ip
  })
}

const routes = [
  {
    name: 'roster',
    path: '/:business/roster',
    component: () => import('../components/RosterPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'kiosk',
    path: '/:business/kiosk',
    component: () => import('../components/KioskPage.vue'),
    meta: {
      requiresAuth: false,
      requiresWhitelist: true
    }
  },
  {
    name: 'equipment',
    path: '/:business/equipment',
    component: () => import('../components/EquipmentPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'tools',
    path: '/:business/tools',
    component: () => import('../components/ToolsPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'booking',
    path: '/:business/:bookingNumber?',
    component: () => import('../components/CheckInPage.vue')
  },
  {
    name: 'location',
    path: '*',
    component: () => import('../components/LocationChooser.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * The following approach is used instead of getRedirectResult() as it
 * will persist the login, but it will cause a loop in incognito mode
 * due to https://github.com/firebase/firebase-js-sdk/issues/3004
 */
firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

/**
 * Setup a route guard to authentiate routes with Firebase and
 * Google Auth
 */
const provider = new firebase.auth.GoogleAuthProvider()
provider.setCustomParameters({
  prompt: 'select_account',
  hd: process.env.VUE_APP_FIREBASE_AUTH_USER_DOMAIN
})
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const currentUser = await firebase.getCurrentUser()
  if (!requiresAuth) next()
  else if (!currentUser) firebase.auth().signInWithRedirect(provider)
  else if (!currentUser.email.endsWith(process.env.VUE_APP_FIREBASE_AUTH_USER_DOMAIN)) {
    firebase.auth().signOut()
    next(to)
  } else next()
})

/**
 * Setup a route guard to restrict routes by whitelisted IP addresses.
 */
router.beforeEach(async (to, from, next) => {
  const requiresWhitelist = to.matched.some(record => record.meta.requiresWhitelist)
  if (!requiresWhitelist) next()
  else {
    const business = to.params.business
    const whitelist = await config(business).whitelist()
    const ipAddress = await ip()
    // load the route if the ip address is whitelisted
    if (whitelist.includes(ipAddress)) next()
    // load the route if no whitelist is defined, or if
    // all IP addresses are allowed
    else if (whitelist.includes('0.0.0.0')) next()
    else {
      // IP is not whitelisted, do not load this route
      console.error(`${ipAddress} is not allowed to use the kiosk view.`)
      next(false)
    }
  }
})

// add the router state to the store
sync(store, router)

export default router

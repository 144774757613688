import Vue from 'vue'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import fr from 'vuetify/es5/locale/fr'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { en, fr },
    current: process.env.VUE_APP_I18N_LOCALE
  },
  theme: {
    themes: {
      light: {
        primary: '#f3831f',
        secondary: '#6cad47',
        accent: '#32aaee',
        error: colors.red.accent3,
        warn: colors.yellow.darken2
      }
    }
  }
})

/**
 * Send an SMS message via Cloud Function sms service
 * @class
 */
class SMS {
  /**
  * @constructor
  * @param {Object} firebase - initialized firebase app
  * @param {string} business - name of business
  */
  constructor ({ firebase }, business) {
    this.business = business
    // add a callable cloud function as a member of this class
    this.sms = firebase.functions().httpsCallable('sms')
  }

  send (phone, message) {
    return this.sms({
      business: this.business,
      phone: phone,
      message: message
    }).then(res => res.data)
  }
}

export default SMS

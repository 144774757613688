/**
 * Bookings Model.  Fetches details given Zaui booking numbers.
 * @class
 */
class Bookings {
  /**
   * @constructor
   * @param {Object} firebase - initialized firebase app
   * @param {string} business - name of api-supported business
   * @param {number} id - booking number
   */
  constructor ({ firebase }, business) {
    this.business = business
    // make a callable Firebase cloud function a member of this class
    const functions = firebase.functions()
    this.getZauiBooking = functions.httpsCallable('getZauiBooking')
    this.getDailyBookings = functions.httpsCallable('getDailyBookings')
    this.setZauiWaivers = functions.httpsCallable('setWaiverSigned')
    this.checkIn = functions.httpsCallable('checkIn')
  }

  /**
   * Get booking details via Firebase function
   * @param {number} id - Zaui booking number
   */
  get (id) {
    return this.getZauiBooking({ business: this.business, id: id }).then((res) => {
      return res.data
    })
  }

  /**
   * Get all bookings
   * @param {string} date - YYYY-MM-DD
   * @return {Promise<Array>} Resolves with an array of all bookings for the given date.
   */
  getAll (date) {
    return this.getDailyBookings({ business: this.business, date }).then((res) => {
      return res.data
    })
  }

  /**
   * Mark an activity on a booking as having all signed waivers.
   * @param {number} id - Zaui booking number
   * @param {number} clientId - Zaui client ID.  This is returned with the booking details.
   * @param {Object} activity
   * @param {number} activity.activityId
   * @param {string} activity.activityDate - YYYY-MM-DD
   * @param {string} activity.activityTime - HH:mm:ss
   * @param {boolean} signed - true to mark all guests as having signed waivers
   * @returns {Promise}
   */
  setWaiverSigned (booking, activity, signed) {
    const options = {
      business: this.business,
      id: booking.id,
      clientId: booking.clientId,
      activityId: activity.activityId,
      activityDate: activity.activityDate,
      activityTime: activity.activityTime,
      signed: signed
    }
    return this.setZauiWaivers(options)
  }

  /**
   * Check-in a booking
   * @param {Object} booking
   * @param {Object} activity
   * @param {boolean} checkIn
   * @returns {Promise}
   */
  setCheckedIn (booking, activity, checkIn) {
    return this.checkIn({
      business: this.business,
      id: booking.id,
      activityId: activity.activityId,
      activityDate: activity.activityDate,
      activityTime: activity.activityTime,
      checkIn: checkIn
    })
  }
}

export default Bookings

import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/functions'
import 'firebase/compat/auth'

/**
 * Firebase app.  Many models require firebase as a dependency.
 * Configuration values are loaded from `.env` and can be found
 * in the Firebase console.
 */
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}

firebase.initializeApp(firebaseConfig)

// connect to emulators for development - to enable, create a
// `.env.local` file and set VUE_APP_EMULATOR=true, then rebuild
if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_EMULATOR) {
  firebase.database().useEmulator('localhost', 9000)
  firebase.functions().useEmulator('localhost', 5001)
}

export default firebase

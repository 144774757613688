import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js'
import { roster, bookingIndex } from '../models/index.js'
dayjs.extend(isSameOrBefore)

class Clean {
  /**
   * @constructor
   * @param {string} business - Name of business
   */
  constructor (business) {
    if (!business) throw TypeError('bad or missing business name')
    this.business = business
    this.roster = roster // help with unit testing
    this.bookingIndex = bookingIndex // help with unit testing
  }

  /**
   * Given a daily roster, get a list of unique bookings
   * @private
   * @param {Roster} roster
   * @returns {Promise<number[]>} Resolves with array of booking numbers
   */
  async getDailyBookings (roster) {
    const guests = await roster.getDailyGuests()
    const bookings = new Set()
    guests.forEach((item) => {
      bookings.add(item.guest.booking)
    })
    return [...bookings]
  }

  /**
   * Remove bookings from the Booking Index
   * @private
   * @param {number[]} bookings - An array of booking numbers to remove
   */
  removeBookings (bookings) {
    return Promise.all(bookings.map((booking) => {
      return this.bookingIndex.removeBooking(this.business, booking)
    }))
  }

  /**
   * Remove all rosters and bookings for the given dates
   * @param {string} start - YYYY-MM-DD
   * @param {string} end - YYYY-MM-DD
   * @returns {Promise} Resolves on success
   */
  async removeRostersAndBookings (start, end) {
    if (!start || !end) throw TypeError('start and end date required')
    let date = dayjs(start)
    while (date.isSameOrBefore(end)) {
      const dailyRoster = this.roster(this.business, { activityDate: date.format('YYYY-MM-DD') })
      const bookings = await this.getDailyBookings(dailyRoster)
      await this.removeBookings(bookings)
      await dailyRoster.remove()
      date = date.add(1, 'day')
    }
  }
}

export default Clean

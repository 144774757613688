import Vue from 'vue'
import Vuex from 'vuex'

/**
 * To keep the webpack entry point small, Vuex modules
 * are loaded by the Stepper component.
 */

Vue.use(Vuex)
export default new Vuex.Store()

/**
 * The Roster represents all the guests on a particular activity date and time.
 * Guests can be added and removed, and each guest is linked to a booking.
 * @class
 */
class Roster {
  /**
   * @constructor
   * @param {Object} firebase - initialized firebase app
   * @param {Object} logger - a logger instance.  Client and Server use different classes.
   * @param {string} business - name of business
   * @param {Object} activity
   * @param {Object} activity.activityDate
   * @param {Object} activity.activityTime
   * @param {Object} activity.activityId
   */
  constructor ({ firebase, logger }, business, activity) {
    if (!business || !activity) throw TypeError('bad or missing business or activity')
    this.firebase = firebase
    this.logger = logger
    this.business = business
    this.dateRef = firebase.database().ref()
      .child(business)
      .child('rosters')
      .child(activity.activityDate)
    if (activity.activityTime && activity.activityId) {
      this.rosterRef = this.dateRef.child(activity.activityTime).child(activity.activityId)
      this.guestRef = this.rosterRef.child('guests')
    }
  }

  /**
   * Get all guests on a roster
   * @returns {Promise} Resolves with a Map of guest objects.
   */
  getGuests () {
    if (!this.guestRef) return Promise.reject(Error('Cannot call getGuests() on a daily Roster'))
    return this.guestRef.once('value').then((snapshot) => {
      const guests = new Map()
      snapshot.forEach((guest) => {
        guests.set(guest.key, guest.val())
      })
      return guests
    })
  }

  /**
   * Get a map of all guests for this roster date
   * @returns {Promise<Map>} Resolves with a Map of guests containing both a Roster and the guest data
   */
  async getDailyGuests () {
    const dailyGuests = new Map()
    const rosters = await this.getDailyRosters()
    for (const roster of rosters) {
      const guests = await roster.getGuests()
      guests.forEach((guest) => {
        dailyGuests.set(guest.key, { guest, roster })
      })
    }
    return dailyGuests
  }

  /**
   * Get an array of Rosters for the current date
   */
  getDailyRosters () {
    const rosters = []
    return this.dateRef.once('value').then((times) => {
      times.forEach((tourId) => {
        tourId.forEach((roster) => {
          const activity = this.activityFromRef(roster.ref)
          const newRoster = new Roster({ firebase: this.firebase, logger: this.logger }, this.business, activity)
          rosters.push(newRoster)
        })
      })
      return rosters
    })
  }

  /**
   * Get all guests for a certain booking.  This method can be more efficient
   * than calling getGuests() and then filtering, as it uses a more specific
   * query.
   * @param {number} bookingId - Zaui booking ID, can be a number or a string
   * @returns {Promise} Resolves with Map of Guests
   */
  getGuestsByBooking (bookingId) {
    if (!bookingId || isNaN(bookingId)) return Promise.reject(TypeError('bad or missing bookingId'))
    return this.guestRef.orderByChild('booking').equalTo(bookingId.toString()).once('value').then((snapshot) => {
      const guests = new Map()
      snapshot.forEach((guest) => {
        guests.set(guest.key, guest.val())
      })
      return guests
    })
  }

  /**
   * Handle events triggered by updates to the roster database.
   * @param {function} callback - the argument is an object representing the modified Guest
   */
  onGuestUpdated (callback) {
    this.guestRef.off('child_changed')
    this.guestRef.on('child_changed', (snapshot) => {
      callback(snapshot.val())
    })
  }

  onGuestAdded (callback) {
    this.guestRef.off('child_added')
    this.guestRef.on('child_added', (snapshot) => {
      callback(snapshot.val())
    })
  }

  /**
   * Handle events triggerd by guests being removed from the database.
   * @param {function} callback - the argument is an object representing the guest that was deleted.
   */
  onGuestRemoved (callback) {
    this.guestRef.off('child_removed')
    this.guestRef.on('child_removed', (snapshot) => {
      callback(snapshot.val())
    })
  }

  /**
   * Adds a blank guest to the roster database, returning a guest object with a key
   * used to sort and reference the guest later.  After a guest is created,
   * the details can be updated using updateGuest().
   * @param {number} bookingId - Associate the blank guest with this booking number.
   * @returns {Promise} Returns a blank guest object that contains a unique database key.
   * The key is not based on the guest details, so it remains contant even if the
   * details do not.
   */
  createGuest (bookingId) {
    if (!bookingId || isNaN(bookingId)) return Promise.reject(TypeError('bad or missing bookingId'))
    const newGuestRef = this.guestRef.push() // get a key
    const guest = {
      firstName: '',
      lastName: '',
      dob: '',
      waiver: false,
      key: newGuestRef.key,
      booking: bookingId.toString(),
      paid: false,
      checkedIn: false,
      weight: '',
      footwear: false,
      location: ''
    }
    guest.key = newGuestRef.key
    return newGuestRef.set(guest).then(() => {
      return guest
    })
  }

  /**
   * Get an array of blank guests that may be on the roster
   * @private
   * @param {number} bookingId
   * @returns {Promise} Resolves with an array of guest objects which do not have
   * names or DOBs assigned.
   */
  async getBlankGuests (bookingId) {
    if (!bookingId || isNaN(bookingId)) return Promise.reject(TypeError('bad or missing bookingId'))
    return this.guestRef.orderByChild('booking').equalTo(bookingId).once('value').then((snapshot) => {
      const blankGuests = []
      snapshot.forEach((entry) => {
        const guest = entry.val()
        if (guest.firstName === '' && guest.lastName === '' && guest.dob === '') blankGuests.push(guest)
      })
      return blankGuests
    })
  }

  async rebookGuest (guest) {
    if (!guest || !guest.booking || isNaN(guest.booking)) throw TypeError('bad or missing guest')
    // create a copy to avoid mutating the guest key
    const rebookedGuest = Object.assign({}, guest)
    // See if there are any blank guests on this roster that can be updated
    // with the incoming guest details.  Blank guests will exist
    // if this roster has been viewed prior to this guest being rebooked.
    // If there are no blank guests, re-use the existing guest key.
    // An alternative approach might be to just delete all blank guests?
    const blankGuests = await this.getBlankGuests(guest.booking)
    if (blankGuests.length > 0) rebookedGuest.key = blankGuests[0].key
    // Use `update()` as it will either update the blank guest or create
    // a new one as needed
    await this.guestRef.child(rebookedGuest.key).update(rebookedGuest)
    return rebookedGuest
  }

  /**
   * Write an updated guest object to the database.  Note, if the object
   * has not changed, no update is performed and therefore no 'changed' event
   * will fire.  Unlike the firebase `update` method, this will only update
   * if the guest already exists in the database.
   * @param {Object} guest
   * @returns {Promise}
   */
  async updateGuest (guest) {
    if (!guest.key) throw TypeError('bad or missing guest key')
    const ref = this.guestRef.child(guest.key)
    const guestData = await ref.once('value') // fetch just to test if it exists
    if (!guestData.exists()) throw Error('cannot update guest that does not yet exist')
    else await this.guestRef.child(guest.key).update(guest)
    return guest
  }

  /**
   * Update a guest, but only if the guest exists and is not currently marked as
   * being edited.  Transactions are used to ensure two clients cannot edit
   * concurrently.
   * @param {Object} guest
   * @returns {Promise<boolean>} Resolves true if guest was updated, false otherwise
   */
  async updateGuestWithLocking (guest) {
    if (!guest.key) throw TypeError('bad or missing guest key')
    return this.guestRef.child(guest.key).transaction(existingGuest => {
      if (existingGuest === null) throw Error('cannot update guest that does not exist yet')
      else if (existingGuest.editing === true) return undefined // abort, guest is already being edited
      else return guest // allow the new guest data to be written
    }).then(({ committed, snapshot }) => committed)
  }

  /**
   * Remove a guest object from the database.
   * @param {Object} guest
   */
  removeGuest (guest) {
    if (!guest || !guest.key) return Promise.reject(TypeError('bad or missing guest key'))
    return this.guestRef.child(guest.key).remove()
  }

  getTeam () {
    return this.rosterRef.child('team').once('value').then(snapshot => snapshot.val())
  }

  setTeam (team) {
    return this.rosterRef.child('team').set(team)
  }

  /**
   * Create a Team listener
   * @param {function} callback function to be invoked when the Team value changes.
   * Callback should take two arguments:  a value and an activity Object.
   */
  onTeamChanged (callback) {
    this.rosterRef.child('team').on('value', (snapshot) => {
      const activity = this.activityFromRef(snapshot.ref)
      callback(snapshot.val(), activity)
    })
  }

  /**
   * Create an activity object from a Firebase Database Reference
   * Rosters have activity info encoded in the path but not in the value.
   * This method allows activity info to be returned in event listeners
   * without having to store redundant activity information.
   * @private
   * @param {DatabaseReferece} ref
   * @returns {Object} Activity object
   */
  activityFromRef (ref) {
    const url = new URL(ref.toString())
    const path = decodeURIComponent(url.pathname)
    const parts = path.split('/')
    if (parts.length < 5) throw Error('Path too short, cannot determine activity time or ID')
    return {
      activityDate: parts[3],
      activityTime: parts[4],
      activityId: parts[5]
    }
  }

  /**
   * Store details of when the roster was printed
   * @param {Object} printed
   * @param {string} printed.user - name of user
   * @param {string} printed.time - ISO8601 date and time
   */
  setPrinted (printed) {
    if (!printed || !printed.user || !printed.time) return Promise.reject(TypeError('setPrinted required argument: {user, time}'))
    return this.rosterRef.child('printed').set(printed)
  }

  /**
   * Retrieve details about when the roster was printed
   * @returns {Promise} Resolves with {user, time}
   */
  getPrinted () {
    return this.rosterRef.child('printed').once('value').then(snapshot => snapshot.val())
  }

  /**
   * Create a listener for changes to the Printed status
   * @param {function} callback function with value and activity arguments
   */
  onPrintedChanged (callback) {
    this.rosterRef.child('printed').on('value', (snapshot) => {
      const activity = this.activityFromRef(snapshot.ref)
      callback(snapshot.val(), activity)
    })
  }

  setNotes (notes) {
    if (notes) return this.rosterRef.child('notes').set(notes)
  }

  getNotes (notes) {
    return this.rosterRef.child('notes').once('value').then(snapshot => snapshot.val())
  }

  onNotesChanged (callback) {
    this.rosterRef.child('notes').on('value', (snapshot) => {
      const activity = this.activityFromRef(snapshot.ref)
      callback(snapshot.val(), activity)
    })
  }

  /**
   * Remove the current roster from the database.  If the roster is for a
   * specific activity, only that activity is removed, but if the roster is for
   * an entire day, ALL activities for that day will be removed.
   * @returns {Promise}
   */
  remove () {
    if (this.rosterRef) return this.rosterRef.remove()
    else if (this.dateRef) return this.dateRef.remove()
  }
}

module.exports = Roster

/**
 * Global mixins that will be added to every component.
 * These mixins are loaded via `../main.js`
 */
export default {
  methods: {
    /**
     * Get a content string (or HTML) from Vuex
     * @param {string} key - use dot notation for sub keys (eg. 'welcome.title')
     */
    $content (key) {
      const locale = this.$i18n.locale // en or fr
      let value = this.$store.getters.content(`${key}.${locale}`) // add locale to key
      if (!value) value = this.$store.getters.content(key) // if no locale, use root
      return value
    },
    /**
     * Allow components to register their required Vuex modules, but only if
     * not already registered.
     * @param {string} name - name of module
     * @param {Object} module - module to register
     */
    registerStoreModule (name, module) {
      const store = this.$store
      if (!(store && store.state && store.state[name])) {
        store.registerModule(name, module, { preserveState: false })
      }
    }
  }
}

import firebase from './firebase.js'
import Bookings from './bookings.js'
import Roster from '../../functions/shared/roster.js'
import Rebook from '../../functions/shared/rebook.js'
import BookingIndex from '../../functions/lib/booking-index.js'
import Waiver from './waiver.js'
import Config from './config.js'
import Content from './content.js'
import Manifest from './manifest.js'
import SMS from './sms.js'
import Logger from './logger.js'
import Utils from './utils.js'
import Clean from './clean.js'

/**
 * This is the main access point for all the models and abstracts away
 * the dependency injection.
 */
export const logger = new Logger({ firebase })
export const bookings = (business) => new Bookings({ firebase }, business)
export const roster = (business, activity) => new Roster({ firebase, logger }, business, activity)
export const waiver = (business) => new Waiver({ firebase }, business)
export const config = (business) => new Config({ firebase }, business)
export const content = (business) => new Content({ firebase }, business)
export const manifest = (business) => new Manifest({ firebase }, business)
export const sms = (business) => new SMS({ firebase }, business)
export const rebook = (business) => new Rebook({ firebase }, business)
export const utils = Utils
export const bookingIndex = new BookingIndex(firebase)
export const clean = (business) => new Clean(business)

export default {
  bookings,
  roster,
  waiver,
  config,
  content,
  manifest,
  sms,
  rebook,
  logger,
  utils,
  bookingIndex,
  clean
}
